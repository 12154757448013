import "../src/scss/main.scss";
import Header from "./component/header";
import Screen from "./screen/index";

function App() {
  return (
    <>
      <Header />
      <Screen />
    </>
  );
}

export default App;
