import React from 'react'

function Home() {
    return (
        <section id='home' className='d-flex j-c-center flex-column'>
            <span>
                Hi, my name is
            </span>
            <h1>
                Aman Maharjan
            </h1>
            {/* <div>
                I build things for the web.
            </div> */}
        </section>
    )
}

export default Home