import React from 'react'
import Menu from '../menu'

function Header() {
    return (
        <header className='header d-flex j-c-between px-5 align-items-center'>
            <div>
                <a href='/#'>LOGO</a>
            </div>
            <div>
                <Menu />
            </div>
        </header>
    )
}

export default Header