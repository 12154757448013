import React from 'react'
import Home from './home';

function Screen() {
  return (
    <>
      <Home />
      <Home />
    </>
  )
}

export default Screen