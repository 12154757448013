import React from 'react'

function Hamburger(props) {
    return (
        <div className='hamburger-container'>
            <input type="checkbox" id="checkbox2" className="checkbox2 visuallyHidden" onClick={(e) => props.cbOpen(e.target.checked)} />
            <label htmlFor="checkbox2">
                <div className="hamburger hamburger2">
                    <span className="bar bar1"></span>
                    <span className="bar bar2"></span>
                    <span className="bar bar3"></span>
                    <span className="bar bar4"></span>
                </div>
            </label>
        </div>
    )
}

export default Hamburger