import React, { useState } from 'react'
import Hamburger from '../hamburger';

const data = [
    { "name": "About", "link": "/#about" },
    { "name": "Experience", "link": "/#experience" },
    { "name": "Work", "link": "/#work" },
    { "name": "Contact", "link": "/#contact" },
];

function Menu() {

    const [navView, setNavView] = useState(false);

    return (
        <>
            <div className={navView ? 'nav-active' : 'nav'} >
                <nav className='d-flex gap-5'>
                    {
                        data.map(({ name, link }, index) => (
                            <a className='' key={`nav-${index}`} href={link}>{name.toUpperCase()}</a>
                        ))
                    }
                </nav>
            </div>
            <Hamburger cbOpen={setNavView} />
        </>
    )
}

export default Menu